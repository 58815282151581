import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import colors from "src/styles/colors"
import fonts from "src/styles/fonts"
import breakpoints from "src/styles/breakpoints"
import { Col, Row } from "styled-bootstrap-grid"
import Image from "../Blocks/Image"
import Slider from "react-slick/lib"
import Text from "../LandingPage/ReviewText"

const CarouselContainer = styled.div`
.slick-list{padding-left: 100%;}
  width: 100%;
  height: auto;
  background:  ${props => props.blue ? colors.royalBlue : "#EB9CC3"};

  #stickyTitle0 {
    margin-top: 150px;
    margin-left: 30px;
    margin-right: 30px;
    line-height: normal;
    opacity: 1;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
  }
  #stickyTitle1 {
    margin-top: 300px;
    margin-left: 30px;
    margin-right: 30px;
    line-height: normal;
    opacity: 0;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
  }
  #stickyTitle2 {
    margin-top: 300px;
    margin-left: 30px;
    margin-right: 30px;
    line-height: normal;
    opacity: 0;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
  }
  #stickyTitle3 {
    margin-top: 300px;
    margin-left: 30px;
    margin-right: 30px;
    line-height: normal;
    opacity: 0;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
  }
  #stickyTitle4 {
    margin-top: 300px;
    margin-left: 30px;
    margin-right: 30px;
    line-height: normal;
    opacity: 0;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
  }


`

const InternalLink = styled.button`

  filter: drop-shadow(3.16071px 3.16071px 0px rgba(0, 0, 0, 0.25));
  
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 300px;
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.25);
  
  border-width: 2px;
  border-style: solid;
  transition: 0.1s background-color linear, 0.1s color linear;
  padding: .4em 1.8em;

  text-transform: uppercase;
  text-decoration: none;
  background: transparent;
  font-size: calc(1vw + 12px);
  line-height: 3vw;
  cursor: pointer;
  display: flex;
  justify-content: left;
  
  background: #319AD5;
  border-color:  #319AD5;
  color: white;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 900;
  @media ( min-width: 1600px)
  {
    font-size: calc(16px + 12px);
    line-height: 48px;
  }
  @media (max-width: ${breakpoints.md}) {
    font-size: 18px;
    bottom: 14px;
    position: relative;
    display: inline-block;
  justify-content: center;
  
  }
`
const HeroContainer = styled.div`
  ${
    "" /* padding-top: ${MOBILE_HEADER_HEIGHT}px;
 

  @media (min-width: ${breakpoints.md}) {
    padding-top: ${DESKTOP_HEADER_HEIGHT}px;
  } */
  }
`

const SlideRow = styled(Row)`
  max-width: 1600px;
  margin: 0 auto;
`

const Numbers = styled.div`
  font-size: calc(3vw + 1rem);
  font-weight: bold;
  margin-bottom: 1vh;
`
const HeadlineUnit = styled.div`

  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  @media (max-width: ${breakpoints.md}) {
    margin: 0 auto;
    position: static;
    top: auto;
    -ms-transform: translateY(0%);
    transform: translateY(0%);
  }
`

const Name = styled.h4`
text-align: left;
color:  ${props => props.blue ? colors.pink : "#824148"};
@media (max-width: ${breakpoints.md}){
    text-align: center;
    margin-bottom: 20px;
}
`
const StyledImage = styled.img`
  border-radius: 25px;
  width: 100%;
  opacity: ${props => props.faded ? .5 : 1};
  -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
  @media (max-width: ${breakpoints.lg}) {
    width: 100%;
      object-fit: cover;
  }
  @media (max-width: ${breakpoints.md}) {
      ${'' /* height: 400px; */}
      width: 75vw;
      object-fit: cover;
      border-radius: 50px;
      padding: 25px;
  }
`

const StyledImageCarousel = styled(Image)``

const Icon = styled.img`
  width: 4vw;
`
const IconCarousel = styled.img`
  left: 1vw;
  position: relative;
  width: calc(2.5vw + 0.4em);
  @media (max-width: ${breakpoints.md}) {
    width: 18vw;
    padding: 10px;
    
  }
`

const ImageWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 15%;
  ${"" /* margin-right: 30px; */}
  ${"" /* top: 175px; */}
  ${"" /* margin-bottom: 150px; */}
  position: sticky;
`
const StyledImageWrapper = styled.div`
 

 margin-top: 10%;
 display: flex;
 padding: 5%;
 justify-content: center;
  @media (min-width: ${breakpoints.xl})
  {
    ${'' /* padding-left: 15%;
    padding-right: 15%; */}
  }
  @media (max-width: ${breakpoints.md}) {
    margin-left: 20px;
    margin-right: 20px;
    ${'' /* margin-right: 15%; */}
    ${'' /* height: 400px;
    overflow:hidden;
    border-radius: 25px; */}
    
  }
  ${'' /* margin-right: auto;
  margin-left: 15%;
  
  margin-top: 15%; */}
`

const Header = styled.h2`
  text-align: center;
  color: #DCCB49;
  padding-top: 5%;
  
`

const P = styled.p`
  color: white;
  margin: 0 auto;
  text-align: center;
  width: 50%;
  font-size: calc(0.6vw + 1rem);
  line-height: 122%;
  padding-top:20px;
  @media (max-width: ${breakpoints.md})
  {
    padding-top:20px;
    width: 90%;
  }
`
const NumberWrapperCarousel = styled.div`
  display: flex;
  justify-content: left;
  @media (max-width: ${breakpoints.md}) {
    text-align: center;
    justify-content: center;
    
  }
`

const HeadlineWrapperCarousel = styled.div`
  display: flex;
  justify-content: left;

  margin-left: 4%;
  margin-right: 4%;
  text-align: left;
  @media (max-width: ${breakpoints.md}) {
    text-align: center;
    justify-content: center;
    margin: 0 auto;
 
  }
`

const TextWrapperCarousel = styled.div`
  ${'' /* display: flex;
  justify-content: center; */}
  text-align: center;
  margin-left: 4%;
  margin-right: 4%;
`
const Description = styled(Text)``

const HeadlineCarousel = styled.h2`
margin-bottom: 10px;
  color: white;
  text-transform: uppercase;
  font-size: calc(2vw + 1rem);
  text-align: left;
  line-height: 100%;
  font-weight: 900;
  @media (max-width: ${breakpoints.md}) {
    width: 300px;
    font-size: 25px;
    line-height: 25px;
    text-align: center;
    justify-content: center;
  }
`

const Headline = styled.h2`
  color: ${colors.royalBlue};
  text-transform: uppercase;
`

const HeadlineWrapper = styled.div`
  ${'' /* margin-top: 15%; */}
  margin-left: 15%;
  line-height: 45px;
  color: ${colors.royalBlue};
  margin-right: 10%;
  @media (max-width: ${breakpoints.md}) {
    margin: 0 auto;
    width: 80%;
    ${'' /* margin-top: 15%; */}
    ${'' /* margin: 0 auto; */}
    ${'' /* margin-top: 0%; */}
  }
`

const Img = styled.img`
  border-radius: 20px;
  width: 100%;
  height: 100%;
`
const CoolRow = styled(Row)`
  padding-bottom: 100px;
`

const Arrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${(props) => (props.next ? "right: 10px" : "left: 10px")};
  cursor: pointer;
  display: block;
  @media (max-width: breakpoints.md){
      opacity: 0;
  }
  ::after {
    content: "";
    ${'' /* display: block; */}
    width: 2vw;
    height: 2vw;
    border-style: solid;
    border-color: #000;
    border-width: 1px 1px 0 0;
    margin: calc((calc(2vw + 20px) - 2vw) / 2);
    transform: ${(props) => (props.next ? "rotate(45deg)" : "rotate(225deg)")};
  }
`

const StyledSlider = styled(Slider)`
   


    width: 80%; 
    @media (max-width: ${breakpoints.md})
    {
        width: auto;
    }
  .slick-dots {
    @media (max-width: ${breakpoints.md}) {
       margin-bottom: 20px;
        ${'' /* position: absolute;
        height: 100px;
        top: 0; */}
        padding-bottom: 0;
    
      }
      bottom: 0;
      
      padding-bottom: 2vh;
    position:relative;
    li {
      width: 50px;
      height: 50px;
      @media (max-width: ${breakpoints.md}) {
        width: auto;
        
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .slick-dots li {
    @media (max-width: ${breakpoints.md}) { 
      height: auto;
    }
  }
  .slick-arrow {
    display: none!important;
    @media (max-width: ${breakpoints.md}) { 
      display: none!important;
    }

    ::before {
      color: ${colors.royalBlue};
    }
  }
  .slick-prev {
   
    z-index: 1;
    ::before {
      font-size: 50px;
    }
    left: 0%;
    @media (min-width: 1870px){
      left: 10%;
    }
    ${'' /* top: 25%; */}
  }
  .slick-next {

    ::before {
      font-size: 50px;
    }
    @media (min-width: 1870px){
      right: 10%;
    }
    right: 31px;
    ${'' /* top: 25%; */}
  }
`

const StickyCarouselBlock = (props) => {
  const breakpoint = 700;
  const [slideIndex, setSlideIndex] = useState(0);
  let slides = props.slide
  const sliderRef = useRef()
  const settings = {
    ref: sliderRef,
    arrows: true,
    dots: true,
    // slidesPerRow: 1,
    slidesToShow: 1.5,
    // speed: 50,
    infinite: true,
    // fade: true,
    dotsClass: "slick-dots",
    autoPlay: true,
    autoplaySpeed: 2000,
   

    beforeChange: (_currentSlide, nextSlide) => {
         // The error occurs here
         const slideElement = sliderRef.current.innerSlider.list.querySelector('.slick-current') //.innerSlider.list

        setSlideIndex(nextSlide)
        //  console.log('slide element'+ JSON.stringify(sliderRef));
      
        //  if (slideElement) {
        //      try {
        //          let classArray = slideElement.firstChild.firstChild.classList;
        //          console.log(slideElement)
        //          console.log(classArray)
        //          for (const c of classArray) {
        //             if (c.startsWith('slide'))
        //             {
        //               console.log(c)
        //               let slideArray = c.split('slide');
        //               setSlideIndex(parseInt(slideArray[1]));
        //               if (slideIndex === 0)
        //               {
                        
        //               }
        //             }
        //          }
        //         //  setSlideIndex(parseInt(slideElement.dataset.index))
        //          console.log(slideIndex)
            //  } catch (e){
            //      console.log(e)
            //  }
           
        //  }
     },
    
    
    // responsive: [
    //   {
    //     breakpoint: 768,
    //     settings: {
    //       dots: true,
    //       slidesPerRow: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       dots: true,
    //       slidesPerRow: 1,
    //     },
    //   },
    // ],
  }
  const changeSlide = (value) => {
    
    if (value === -1) {
      sliderRef.current?.slickPrev()
    }
    if (value === 1) {
      sliderRef.current?.slickNext()
    }
  }
  // console.log(slides)
  
  const openForm = () => {
    if (typeof window !== "undefined")
    { 
      window._klOnsite = window._klOnsite || [];
      window._klOnsite.push(['openForm', 'RPj3qu']);
    }
 
  }
  console.log("SlideIndex is " + slideIndex);
  
  return (
    <CarouselContainer blue={props.blue ?? false}>
    {
      props.header && 
      <>
    <Header>{props.header}</Header>
    <P> {props.text} </P>
    </>
    }
      {/* <script type="text/javascript" dangerouslySetInnerHTML={{__html: script}}></script> */}
<Row>
<Col md={6} xsOrder="last" >
                    <HeadlineUnit>
                      <HeadlineWrapper>
                        {/* <NumberWrapperCarousel>
                          <Numbers>
                            <IconCarousel
                              src={slide.icon.file.url}
                            ></IconCarousel>
                          </Numbers>
                        </NumberWrapperCarousel> */}
                        <HeadlineWrapperCarousel>
                          <HeadlineCarousel>{slides[slideIndex ? ((slideIndex-1) === -1 ? slides.length-1 : slideIndex-1) : slides.length-1 ].headline}</HeadlineCarousel>
                        </HeadlineWrapperCarousel>
                        <TextWrapperCarousel>
                          <Description
                            content={slides[slideIndex ? ((slideIndex-1) === -1 ? slides.length-1 : slideIndex-1) : slides.length-1 ].description}
                          ></Description>
                         <Name blue={props.blue ?? false}>{slides[ (slideIndex-1) === -1 ? slides.length-1 : slideIndex-1 ].name}</Name>
                         {/* <InternalLink onClick={ openForm }>Get 15% Off!</InternalLink> */}
                        </TextWrapperCarousel>
                      
                      </HeadlineWrapper>
                      
                    </HeadlineUnit>
                    
                  </Col>
                  <Col md={6} xsOrder="first">
      <StyledSlider {...settings} >
        {slides &&
          slides.map((slide, index) => {
            return (
              <>
             
             
                    <StyledImageWrapper  className = {"slide"+index}>
                      <StyledImage
                       
                        faded = {  ( slideIndex === 0 && index == 0 ) ? true : (index != slideIndex-1 && slideIndex!=0) }
                        src={slide.image.file.url}
                        key={index}
                      />
                    </StyledImageWrapper>
                
                  
                
              </>
            )
          })}
      </StyledSlider>
      </Col>
      </Row>
    </CarouselContainer>
  )
}

export default StickyCarouselBlock
