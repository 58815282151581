import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import colors from "src/styles/colors"
import fonts from "src/styles/fonts"
import breakpoints from "src/styles/breakpoints"
import { Col, Row } from "styled-bootstrap-grid"
import CtaButton from "../Core/CtaButton"
import Text from "./Text"

//to do there will be more field added to contentful such as image position grid ratio heading overlap

const BlockContainer = styled.div`
  padding: 5vw;
`

const Background = styled.div`
  padding: 6.6vmax 6vw;
  background: ${(props) =>
    props.bg === "Blue"
      ? colors.royalBlue
      : props.bg === "Pink"
      ? colors.pink
      : colors.lightGrey};
`

const ContentContainer = styled.div`
  padding: 0 4%;
`

const Heading = styled.h2`
  color: ${(props) => (props.bg === "White" ? colors.royalBlue : colors.white)};
  line-height: 1.104;
  margin-top: calc(-1em - 1.5%);
  position: relative;
  z-index: 1;
  display: block;
  span {
    background: ${(props) =>
      props.highlight === "Blue"
        ? colors.royalBlue
        : props.highlight === "Yellow"
        ? colors.yellow
        : "transparent"};
    webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    padding: 0 3%;
  }

  @media (min-width: ${breakpoints.lg}) {
    margin-top: 0;
    width: 130%;
    font-size: 3rem;
  }

  @media (min-width: ${breakpoints.xxl}) {
    width: 100%;
  }
`

const PaddedContent = styled.div`
  margin-top: 18px;
  color: ${(props) => (props.bg === "White" ? colors.black : colors.white)};

  p {
    margin-bottom: 1rem;
    :last-of-type {
      margin-bottom: 0;
    }
    padding: 17px;
    text-align: center;
    font-size: calc(0.24vw + 1rem);
  }

  b {
    ${fonts.workSansBold};
  }

  h2 {
    text-align: center;
    text-transform: uppercase;
  }

  .gatsby-image-wrapper {
    left: calc(50% - 16px);
    margin-top: 17px;
    margin-bottom: 17px;
  }

  div:not(.gatsby-image-wrapper) {
    width: fit-content;
    margin: auto;
  }
`

const ButtonContainer = styled.div`
  margin-top: 17px;
`

const StyledRow = styled(Row)`
  position: relative;
  max-width: 1600px;
  width: 100%;
  margin: auto;
`

const TopPaddedCol = styled(Col)`
  padding-top: 6.6vmax;
`

const SidePaddedCol = styled(Col)`
  padding-left: 8%;
  padding-right: 8%;

  @media (max-width: ${breakpoints.md}) {
    padding-top: 40px;
  }
`

const TextImageBlock = ({
  heading,
  headingHighlight,
  media,
  content,
  ctaLabel,
  ctaLink,
  backgroundColor,
  imagePosition,
}) => {
  return (
    <BlockContainer>
      <Background bg={backgroundColor}>
        <StyledRow>
          {imagePosition === "Right" ? (
            <>
              <SidePaddedCol lg={6} alignSelf="center">
                <ContentContainer>
                  <PaddedContent bg={backgroundColor}>
                    <Heading
                      highlight={headingHighlight}
                      bg={backgroundColor}
                      style={{ width: "100%" }}
                    >
                      <span>{heading}</span>
                    </Heading>
                    <Text content={content} references={content.references} />
                    {ctaLabel && ctaLink && (
                      <ButtonContainer>
                        <CtaButton
                          url={ctaLink}
                          label={ctaLabel}
                          blue={backgroundColor === "White"}
                        />
                      </ButtonContainer>
                    )}
                  </PaddedContent>
                </ContentContainer>
              </SidePaddedCol>
              <TopPaddedCol lg={6} lgOrder="last">
                <GatsbyImage
                  image={media.gatsbyImageData}
                  alt={media.description || heading}
                />
              </TopPaddedCol>
            </>
          ) : (
            <>
              <TopPaddedCol lg={6} alignSelf="top">
                <GatsbyImage
                  image={media.gatsbyImageData}
                  alt={media.description || heading}
                />
              </TopPaddedCol>
              <SidePaddedCol lg={6} lgOrder="last">
                <ContentContainer>
                  <PaddedContent bg={backgroundColor}>
                    <Heading
                      highlight={headingHighlight}
                      bg={backgroundColor}
                      style={{ width: "100%" }}
                    >
                      <span>{heading}</span>
                    </Heading>
                    <Text content={content} references={content.references} />
                    {ctaLabel && ctaLink && (
                      <ButtonContainer>
                        <CtaButton
                          url={ctaLink}
                          label={ctaLabel}
                          blue={backgroundColor === "White"}
                        />
                      </ButtonContainer>
                    )}
                  </PaddedContent>
                </ContentContainer>
              </SidePaddedCol>
            </>
          )}
        </StyledRow>
      </Background>
    </BlockContainer>
  )
}

export default TextImageBlock
