import React from "react"
import styled from "styled-components"
import PageBlock from "../Blocks/PageBlock"
import SimpleBlock from "../Blocks/SimpleBlock"
import PageBlockGroup from "../Blocks/PageBlockGroup"
import TextImageBlock from "../Blocks/TextImageBlock"
import PressBlock from "../Blocks/PressBlock"
import Ticker from "react-ticker"
import GatsbyImage from "gatsby-image"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"

const Divider = styled.img`
  height: 10px;
  padding-right: 0.3em;
  display: inline;
`

const LastDivider = styled.img`
  height: 10px;
  padding-right: 0.5em;
  ${
    "" /* padding-right: 3.3vw;

    @media (max-width: ${breakpoints.md}) {
        padding-right: 4vw;
    } */
  }
`

const TickerImage = styled.img`
  height: 36px;
`

const PinkCity = styled.span`
  color: ${colors.pink};
  font-weight: bold;

  padding-right: 0.5em;
`

const BlueCity = styled.span`
  color: ${colors.lightBlue};
  font-weight: bold;
  padding-right: 0.5em;
`

const RoyalBlueCity = styled.span`
  color: ${colors.royalBlue};
  font-weight: bold;
  padding-right: 0.5em;
`

const City = styled.h4`
    
  font-weight: bold;
  ${'' /* padding-right: 0.5em; */}
`

const CityTitle = styled.h4`
    
  font-weight: bold;
  padding-right: 0.5em;
`


const Div = styled.div`
  ${"" /* left: 40px; */}
  ${
    "" /* white-space: nowrap;
    float: right;
    display: block; */
  }
`

const CityTicker = (props) => {
  // const SetLeft = () => {
  //     let tick = document.getElementsByClassName('ticker__element')
  //     if (tick && tick[0]){
  //         tick[0].style.left = "40px !important";
  //         console.log('tick exists')
  //     }
  //     console.log('fired')

  // }
  // SetLeft();

  console.log("props" + props)
  let colorsArray = [colors.royalBlue,  colors.lightBlue, colors.pink ]
  return (
    <Ticker >
      {({ index }) => (
        <>
          {index % 2 ? (
              
            <City>
              <CityTitle style={{color: colorsArray[index % 3]}}>
              {index % 3 ? "CHEF-CRAFTED" : "LIMITED-EDITION"} {" "}
              <Divider src={props.dividers[index % 3].file.url}></Divider>
              </CityTitle>
            </City>
          ) : (
            <City>
              <CityTitle style={{color: colorsArray[index % 3]}}>
              {index % 3 ? "SUSTAINABLY PACKAGED" : "FLEXIBLE & FLAVORFUL"} {" "}
              <Divider src={props.dividers[index % 3].file.url}></Divider>
              </CityTitle>
            </City>
          )}
        </>
      )}
    </Ticker>
  )
}
export default CityTicker
