import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import colors from "src/styles/colors"
import fonts from "src/styles/fonts"
import breakpoints from "src/styles/breakpoints"
import { Col, Row } from "styled-bootstrap-grid"
import Image from "./Image"
import Slider from "react-slick/lib"
import Text from "../HowItWorks/StickyCarouselText"

const CarouselContainer = styled.div`
  width: 100%;
  height: auto;
  background: white;

  #stickyTitle0 {
    margin-top: 150px;
    margin-left: 30px;
    margin-right: 30px;
    line-height: normal;
    opacity: 1;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
  }
  #stickyTitle1 {
    margin-top: 300px;
    margin-left: 30px;
    margin-right: 30px;
    line-height: normal;
    opacity: 0;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
  }
  #stickyTitle2 {
    margin-top: 300px;
    margin-left: 30px;
    margin-right: 30px;
    line-height: normal;
    opacity: 0;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
  }
  #stickyTitle3 {
    margin-top: 300px;
    margin-left: 30px;
    margin-right: 30px;
    line-height: normal;
    opacity: 0;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
  }
  #stickyTitle4 {
    margin-top: 300px;
    margin-left: 30px;
    margin-right: 30px;
    line-height: normal;
    opacity: 0;
    -webkit-transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
  }
`

const SlideRow = styled(Row)`
  max-width: 1600px;
  margin: 0 auto;
`

const Numbers = styled.div`
  font-size: calc(3vw + 1rem);
  font-weight: bold;
  margin-bottom: 1vh;
`
const HeadlineUnit = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  @media (max-width: ${breakpoints.md}) {
    margin: 0 auto;
    position: static;
    top: auto;
    -ms-transform: translateY(0%);
    transform: translateY(0%);
  }
`

const StyledImage = styled.img`
  border-radius: 25px;
  width: 100%;

  @media (max-width: ${breakpoints.lg}) {
    width: 100%;
      object-fit: cover;
  }
  @media (max-width: ${breakpoints.md}) {
      height: 400px;
      width: 400px;
      object-fit: cover;
      padding: 20px;
  }
`

const StyledImageCarousel = styled(Image)``

const Icon = styled.img`
  width: 4vw;
`
const IconCarousel = styled.img`
  left: 1vw;
  position: relative;
  width: calc(2.5vw + 0.4em);
  @media (max-width: ${breakpoints.md}) {
    width: 18vw;
    padding: 10px;
    
  }
`

const ImageWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 15%;
  ${"" /* margin-right: 30px; */}
  ${"" /* top: 175px; */}
  ${"" /* margin-bottom: 150px; */}
  position: sticky;
`
const StyledImageWrapper = styled.div`
 

 margin-top: 10%;
 display: flex;
 padding: 5%;
 justify-content: center;
  @media (min-width: ${breakpoints.xl})
  {
    padding-left: 15%;
    padding-right: 15%;
  }
  @media (max-width: ${breakpoints.md}) {
    margin-left: 20px;
    margin-right: 20px;
    ${'' /* margin-right: 15%; */}
    ${'' /* height: 400px;
    overflow:hidden;
    border-radius: 25px; */}
    
  }
  ${'' /* margin-right: auto;
  margin-left: 15%;
  
  margin-top: 15%; */}
`

const NumberWrapperCarousel = styled.div`
  display: flex;
  justify-content: left;
  @media (max-width: ${breakpoints.md}) {
    text-align: center;
    justify-content: center;
    
  }
`

const HeadlineWrapperCarousel = styled.div`
  display: flex;
  justify-content: left;

  margin-left: 4%;
  margin-right: 4%;
  text-align: left;
  @media (max-width: ${breakpoints.md}) {
    text-align: center;
    justify-content: center;
    margin: 0 auto;
 
  }
`

const TextWrapperCarousel = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-left: 4%;
  margin-right: 4%;
`
const Description = styled(Text)``

const HeadlineCarousel = styled.h2`
  color: ${colors.royalBlue};
  text-transform: uppercase;
  font-size: calc(2vw + 1rem);
  text-align: left;
  line-height: 100%;
  font-weight: 900;
  @media (max-width: ${breakpoints.md}) {
    width: 300px;
    font-size: 25px;
    line-height: 25px;
    text-align: center;
    justify-content: center;
  }
`

const Headline = styled.h2`
  color: ${colors.royalBlue};
  text-transform: uppercase;
`

const HeadlineWrapper = styled.div`
  margin-top: 15%;
  line-height: 45px;
  color: ${colors.royalBlue};
  margin-right: 10%;
  @media (max-width: ${breakpoints.md}) {
    margin: 0 auto;
    ${'' /* margin-top: 0%; */}
  }
`

const Img = styled.img`
  border-radius: 20px;
  width: 100%;
  height: 100%;
`
const CoolRow = styled(Row)`
  padding-bottom: 100px;
`

const Arrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${(props) => (props.next ? "right: 10px" : "left: 10px")};
  cursor: pointer;
  display: block;
  @media (max-width: breakpoints.md){
      opacity: 0;
  }
  ::after {
    content: "";
    ${'' /* display: block; */}
    width: 2vw;
    height: 2vw;
    border-style: solid;
    border-color: #000;
    border-width: 1px 1px 0 0;
    margin: calc((calc(2vw + 20px) - 2vw) / 2);
    transform: ${(props) => (props.next ? "rotate(45deg)" : "rotate(225deg)")};
  }
`

const StyledSlider = styled(Slider)`
  .slick-dots {
    @media (max-width: ${breakpoints.md}) {
       
        ${'' /* position: absolute;
        height: 100px;
        top: 0; */}
      }
      padding-bottom: 2vh;
    position:relative;
    li {
      width: 50px;
      height: 50px;
      @media (max-width: ${breakpoints.md}) {
        width: auto;
        
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .slick-arrow {
    @media (max-width: ${breakpoints.md}) { 
      display: none!important;
    }

    ::before {
      color: ${colors.royalBlue};
    }
  }
  .slick-prev {
   
    z-index: 1;
    ::before {
      font-size: 50px;
    }
    left: 0%;
    @media (min-width: 1870px){
      left: 10%;
    }
    ${'' /* top: 25%; */}
  }
  .slick-next {

    ::before {
      font-size: 50px;
    }
    @media (min-width: 1870px){
      right: 10%;
    }
    right: 31px;
    ${'' /* top: 25%; */}
  }
`

const StickyCarouselBlock = (props) => {
  const breakpoint = 700

  let slides = props.stickyCarouselSlide

  const sliderRef = useRef()
  const settings = {
    arrows: true,
    dots: true,
    slidesPerRow: 1,
    dotsClass: "slick-dots",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: true,
          slidesPerRow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: true,
          slidesPerRow: 1,
        },
      },
    ],
  }
  const changeSlide = (value) => {
    if (value === -1) {
      sliderRef.current?.slickPrev()
    }
    if (value === 1) {
      sliderRef.current?.slickNext()
    }
  }
  // console.log(slides)
  
  
  return (
    <CarouselContainer>
      {/* <script type="text/javascript" dangerouslySetInnerHTML={{__html: script}}></script> */}

      <StyledSlider {...settings}>
        {slides &&
          slides.map((slide, index) => {
            return (
              <>
                <SlideRow>
                  <Col md={6}>
                    <StyledImageWrapper>
                      <StyledImage
                     
                        src={slide.image.file.url}
                      
                      />
                    </StyledImageWrapper>
                  </Col>
                  <Col md={6} >
                    <HeadlineUnit>
                      <HeadlineWrapper>
                        <NumberWrapperCarousel>
                          <Numbers>
                            <IconCarousel
                              src={slide.icon.file.url}
                            ></IconCarousel>
                          </Numbers>
                        </NumberWrapperCarousel>
                        <HeadlineWrapperCarousel>
                          <HeadlineCarousel>{slide.headline}</HeadlineCarousel>
                        </HeadlineWrapperCarousel>
                        <TextWrapperCarousel>
                          <Description
                            content={slide.description}
                          ></Description>
                        </TextWrapperCarousel>
                      </HeadlineWrapper>
                    </HeadlineUnit>
                  </Col>
                </SlideRow>
              </>
            )
          })}
      </StyledSlider>
    </CarouselContainer>
  )
}

export default StickyCarouselBlock
