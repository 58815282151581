import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import colors from "src/styles/colors"
import fonts from "src/styles/fonts"
import breakpoints from "src/styles/breakpoints"
import { Col, Row } from "styled-bootstrap-grid"
import CtaButton from "../Core/CtaButton"
import Text from "../Blocks/Text"

const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
`
const BlockContainer = styled.div`
  background-color: #07408a;
  margin-top: 10px;
`

const ContentContainer = styled.div`
  max-width: 1600px;
  margin: 0 auto;
`

const ColDiv = styled.div`
  padding: 50px;
  text-align: center;
  @media (max-width: ${breakpoints.md}) {
    padding: 30px;
  }
`
const Img = styled.img`
  width: 22vw;
  border-radius: 25px;
  max-width: 448px;
  @media (max-width: ${breakpoints.md}) {
    width: 300px;
  }
`
const InternalLink = styled.button`
  margin-bottom: 25px;
  filter: drop-shadow(3.16071px 3.16071px 0px rgba(0, 0, 0, 0.25));

  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 300px;
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.25);

  border-width: 2px;
  border-style: solid;
  transition: 0.1s background-color linear, 0.1s color linear;
  padding: 0.4em 1.8em;

  text-transform: uppercase;
  text-decoration: none;
  background: transparent;
  font-size: calc(1vw + 12px);
  line-height: 3vw;
  cursor: pointer;
  display: flex;
  justify-content: left;

  background: #319ad5;
  border-color: #319ad5;
  color: white;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 900;
  bottom: 28px;
  position: relative;
  @media (min-width: 1600px) {
    font-size: calc(16px + 12px);
    line-height: 48px;
  }
  @media (max-width: ${breakpoints.md}) {
    font-size: 18px;
    bottom: 28px;
    position: relative;
    display: inline-block;
    justify-content: center;
  }
`

const Headline = styled.h4`
  padding: 5px;
  font-size: calc(0.6vw + 12px);
  @media (max-width: ${breakpoints.md}) {
    font-size: calc(1vw + 16px);
  }
`

const Description = styled.p`
  color: white;
  font-size: calc(0.6vw + 10px);
  @media (max-width: ${breakpoints.md}) {
    ${"" /* width: 60%; */}

    margin: 0 auto;
    font-size: calc(0.6vw + 14px);
    line-height: 142%;
  }
`

const openForm = () => {
  if (typeof window !== "undefined") {
    window._klOnsite = window._klOnsite || []
    window._klOnsite.push(["openForm", "RPj3qu"])
  }
}

const LandingPageHeaderBlock = ({
  image1,
  headline1,
  description1,
  image2,
  headline2,
  description2,
  image3,
  headline3,
  description3,
}) => {
  if (typeof window !== "undefined") {
    window.addEventListener("klaviyoForms", function (e) {

      try {
        console.log(e.detail)

        if (e.detail.type == "stepSubmit") {
          let old_gtag_data = {
            event_category: "klaviyo form",
            send_to: "UA-185335499-1"
          }
          switch (e.detail.metaData.$step_name) {
            case "Email Opt-In":
              old_gtag_data.event_label = "landing page email opt-in"
              // gtag_data.email = e.detail.metaData.$email
              break
            case "Zip Code":
              old_gtag_data.event_label = "landing page zip code"
              old_gtag_data.zip = e.detail.metaData.$zip
              break
            case "SMS Opt-In":
              old_gtag_data.event_label = "landing page sms opt-in"
              // gtag_data.phone_number = e.detail.metaData.$phone_number
              break
          }

           window.gtag("event", "form_submit", old_gtag_data)
          //todo: add GA4 event
          //add fbq event?
        }
      } catch (err) {
        console.log(err)

      }
    }
  )
  }

  return (
    <BlockContainer>
      <ContentContainer>
        <Row style={{ padding: "5px", marginBottom: "15px" }}>
          <Col md={4}>
            <ColDiv>
              <Img src={image1.file.url}></Img>
              <Headline style={{ color: "#DCCB49" }}>{headline1}</Headline>
              <Description>{description1}</Description>
            </ColDiv>
          </Col>
          <Col md={4}>
            <ColDiv>
              <Img src={image2.file.url}></Img>
              <Headline style={{ color: "#EB9CC3" }}> {headline2}</Headline>
              <Description>{description2}</Description>
            </ColDiv>
          </Col>
          <Col md={4}>
            <ColDiv>
              <Img src={image3.file.url}></Img>
              <Headline style={{ color: "#319AD5" }}>{headline3}</Headline>
              <Description>{description3}</Description>
            </ColDiv>
          </Col>
        </Row>
        <CenterDiv>
          {/* <InternalLink onClick={ openForm }>Get 15% Off!</InternalLink> */}
        </CenterDiv>
      </ContentContainer>
    </BlockContainer>
  )
}

export default LandingPageHeaderBlock
