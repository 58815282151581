import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import colors from "src/styles/colors"
import fonts from "src/styles/fonts"
import breakpoints from "src/styles/breakpoints"
import { Col, Row } from "styled-bootstrap-grid"
import Image from "../Blocks/Image"
import Slider from "react-slick/lib"
import Text from "../LandingPage/LandingPageHowItWorksText"

const CarouselContainer = styled.div`
  width: 100%;
  height: auto;
  background: white;
  max-width: 1600px;
  margin: 0 auto;
`

const SlideRow = styled(Row)`
  max-width: 1600px;
  margin: 0 auto;
`

const Numbers = styled.div`
  font-size: calc(3vw + 1rem);
  font-weight: bold;
  margin-bottom: 1vh;
  @media (max-width: ${breakpoints.md}){
      margin-bottom: 0;
  }
`
const HeadlineUnit = styled.div`

  
  @media (max-width: ${breakpoints.md}) {
    margin: 0 auto;
    position: static;
    top: auto;

  }
`

const StyledImage = styled.img`
  border-radius: 25px;
  width: 100%;

  @media (max-width: ${breakpoints.lg}) {
    width: 100%;
    object-fit: cover;
  }
  @media (max-width: ${breakpoints.md}) {
    height: 400px;
    width: 400px;
    object-fit: cover;
    padding: 20px;
  }
`

const StyledImageCarousel = styled(Image)``

const Icon = styled.img`
  width: 4vw;
`
const IconCarousel = styled.img`

  position: relative;
  width: calc(1.5vw + 1em);
  margin-bottom: 10px;
  @media (max-width: ${breakpoints.md}) {
    width: 12vw;
    margin-bottom: auto;
    ${'' /* padding: 10px; */}
  }
`

const ImageWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 15%;
  ${"" /* margin-right: 30px; */}
  ${"" /* top: 175px; */}
  ${"" /* margin-bottom: 150px; */}
  position: sticky;
`
const StyledImageWrapper = styled.div`
  margin-top: 10%;
  display: flex;
  padding: 5%;
  justify-content: center;
  @media (min-width: ${breakpoints.xl}) {
    padding-left: 15%;
    padding-right: 15%;
  }
  @media (max-width: ${breakpoints.md}) {
    margin-left: 20px;
    margin-right: 20px;
    ${"" /* margin-right: 15%; */}
    ${
      "" /* height: 400px;
    overflow:hidden;
    border-radius: 25px; */
    }
  }
  ${
    "" /* margin-right: auto;
  margin-left: 15%;
  
  margin-top: 15%; */
  }
`

const NumberWrapperCarousel = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: ${breakpoints.md}) {
    text-align: center;
    justify-content: center;
  }
`

const HeadlineWrapperCarousel = styled.div`
  display: flex;
  justify-content: center;

  margin-left: 4%;
  margin-right: 4%;
  text-align: center;
  @media (max-width: ${breakpoints.md}) {
    text-align: center;
    justify-content: center;
    margin: 0 auto;
  }
`

const TextWrapperCarousel = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-left: 4%;
  margin-right: 4%;
`
const Description = styled(Text)``

const HeadlineCarousel = styled.h4`
  color: ${colors.royalBlue};
  text-transform: uppercase;
  ${'' /* font-size: calc(2vw + 1rem); */}
  text-align: center;
  line-height: 100%;
  font-weight: 900;
  @media (max-width: ${breakpoints.md}) {
    width: 300px;
    font-size: 25px;
    line-height: 25px;
    text-align: center;
    justify-content: center;
  }
`

const Headline = styled.h4`
  color: ${colors.royalBlue};
  text-transform: uppercase;
`

const HeadlineWrapper = styled.div`
  ${'' /* margin-top: 15%; */}
  line-height: 45px;
  color: ${colors.royalBlue};
  margin-right: 10%;
  margin-left: 10%;
  @media (max-width: ${breakpoints.md}) {
    margin: 0 auto;
    ${"" /* margin-top: 0%; */}
  }
`

const Img = styled.img`
  border-radius: 20px;
  width: 100%;
  height: 100%;
`
const CoolRow = styled(Row)`
  padding-bottom: 100px;
`
const CoolCol = styled(Col)`
  @media (max-width: ${breakpoints.md}){
      margin-bottom: 25px;
  }
`

const Video = styled.video`
  width: 65%;
  border-radius: 25px;
  max-width: 1054px;

  @media (max-width: ${breakpoints.md}){
      width: 90%;
  }
`



const VideoDiv = styled.div`
  display: flex;
  justify-content: center;

  ${'' /* margin-top: 50px; */}
  margin-bottom: 50px;
`
const CenterDiv = styled.div`
    justify-content: center;
    display: flex;
    padding: 50px;
    color: ${colors.royalBlue};
`
const HowItWorksBlock = (props) => {
  const breakpoint = 700

  let slides = props.stickyCarouselSlide

  return (
    <CarouselContainer>
    <CenterDiv>
        <h2>IT'S EASY AS...</h2>
    </CenterDiv>
      <VideoDiv>
        <Video src={props.video.file.url} controls />
      </VideoDiv>
    
    <Row style={{marginBottom: "50px"}}>
        { 
            slides && slides.map((slide, index) => {
                return (
                    <CoolCol md={4}>
                    <HeadlineUnit>
                    <HeadlineWrapper>

                    <NumberWrapperCarousel>
                          <Numbers>
                            <IconCarousel
                              src={slide.icon.file.url}
                            ></IconCarousel>
                          </Numbers>
                        </NumberWrapperCarousel>
                        <HeadlineWrapperCarousel>
                          <HeadlineCarousel>{slide.headline}</HeadlineCarousel>
                        </HeadlineWrapperCarousel>
                        <TextWrapperCarousel>
                          <Description
                            content={slide.description}
                          ></Description>
                        </TextWrapperCarousel>
                      </HeadlineWrapper>
                    </HeadlineUnit>
                    </CoolCol>
                )
            }
        
            )}
    </Row>
    </CarouselContainer>
  )
}

export default HowItWorksBlock
