import React from "react"
import styled from "styled-components"
import PageBlock from "../Blocks/PageBlock"
import SimpleBlock from "../Blocks/SimpleBlock"
import PageBlockGroup from "../Blocks/PageBlockGroup"
import TextImageBlock from "../Index/TextImageBlock"
import PressBlock from "../Blocks/PressBlock"
import ExplainBlock from "../Blocks/ExplainBlock"
import BenefitsBlock from "../Blocks/BenefitsBlock"
import CityTicker from "src/components/LandingPage/CityTicker"
import CountdownBlock from "src/components/Index/CountdownBlock"
import DeliveryZone from "src/components/Index/DeliveryZone"
import LandingPageHeaderBlock from "src/components/LandingPage/LandingPageHeaderBlock"
import StickyCarouselBlock from "../Blocks/StickyCarouselBlock"
import ReviewBlock from "./ReviewBlock"
import breakpoints from "src/styles/breakpoints"
import colors from "src/styles/colors"
import fonts from "src/styles/fonts"
import ProductLandingPageBlock from "src/components/Index/ProductLandingPageBlock"
import CompetitionBlock from "src/components/LandingPage/CompetitionBlock"
import { Link } from "gatsby"
import LandingPageHowItWorksBlock from "src/components/LandingPage/LandingPageHowItWorksBlock"

const ProductsSubHeadline = styled.h3`
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  font-size: calc(0.8vw + 1rem);
  line-height: 112%;
  /* or 40px */
  margin-bottom: 0.5rem;
margin-top: 2rem;
  text-align: center;
  letter-spacing: -0.01em;
  font-feature-settings: "ordn" on, "ss09" on, "ss10" on, "ss13" on;
  font-weight: 900;
  text-transform: capitalize;
  color: rgb(235, 156, 195);
`

const ProductsHeadline = styled.h3`
  ${fonts.workSansBold};
  font-style: bold;
  font-weight: 900;
  font-size: calc(1.5vw + 1rem);
  line-height: 112%;
  /* or 72px */
  padding: 0px 6vw;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;
  margin-bottom: 20px;
  ${"" /* text-transform: uppercase; */}
  font-feature-settings: "ordn" on, "ss09" on, "ss10" on, "ss13" on;
  @media (min-width: 1600px) {
    padding: 0 96px;
  }
`
const ButtonContainer = styled.div`
  ${"" /* margin-top: 34px; */}
  display: flex;
  align-content: center;
  justify-content: center;
  padding-bottom: 2rem;
`
const ProductBlockWrapper = styled.div`
    height: auto;
    margin-top: 50px;
    margin-bottom:50px;
  
  }

  @media (max-width: ${breakpoints.md}) {
    
  }
`

const HeadlinesWrapper = styled.div`
  margin-left: 20vw;
  margin-right: 20vw;

  @media (max-width: ${breakpoints.md}) {
    margin-left: 2em;
    margin-right: 2em;
  }
`


const Headlines = styled.h2`
  display: flex;
  justify-content: center;
  color: ${colors.royalBlue};
  
`

const Headlines2 = styled.div`
  display: flex;
  justify-content: center;
  color: ${colors.royalBlue};
  padding-bottom: 15px;
`

const InternalLink = styled(Link)`

  filter: drop-shadow(3.16071px 3.16071px 0px rgba(0, 0, 0, 0.25));
  
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 300px;
  box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.25);
  
  border-width: 2px;
  border-style: solid;
  transition: 0.1s background-color linear, 0.1s color linear;
  padding: .4em 1.8em;

  text-transform: uppercase;
  text-decoration: none;
  background: transparent;
=  font-size: calc(1vw + 12px);
  line-height: 3vw;
  cursor: pointer;
  display: inline-block;
  background: #319AD5;
  border-color:  #319AD5;
  color: white;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 900;
  @media ( min-width: 1600px)
  {
    font-size: calc(16px + 12px);
    line-height: 48px;
  }
  @media (max-width: ${breakpoints.md}) {
    font-size: 18px;
    bottom: 14px;
    position: relative;
  }
`

const Blocks = (props) => {
  const blocks = props.blocks

  return (
    <>
      {blocks && blocks.map((block, index) => {
        const { __typename: type } = block
        if (type === "ContentfulLandingPageHeaderBlock")
        {
            return <LandingPageHeaderBlock {...block} key={index}></LandingPageHeaderBlock>
        }
        if (type === "ContentfulSimpleBlock") {
          return <SimpleBlock {...block} key={index} />
        }

        if (type === "ContentfulTextImageBlock") {
          return <TextImageBlock {...block} key={index} />
        }

        if (type === "ContentfulPageBlockGroup") {
          return <PageBlockGroup {...block} key={index} />
        }

        if (type === "ContentfulExplainBlock") {
          return <><ExplainBlock {...block} key={index} /></>
        }

        if (type === "ContentfulPressBlock") {
          return <PressBlock {...block} key={index}  />
        }

        if (type === "ContentfulCountdownBlock") {
          return <CountdownBlock {...block} key={index} />
        }
        if (type === "ContentfulBenefitsBlock") {
          return <BenefitsBlock {...block} key={index}  />
        }

        if (type === "ContentfulTickerBlock") {
          return <CityTicker {...block} key={index}  />
        }
        if (type === "ContentfulStickyCarouselBlock") {
          return <LandingPageHowItWorksBlock {...block} key={index} />
        }
        if (type === "ContentfulCompetitionBlock") {
            return <CompetitionBlock {...block} key={index}/>
        }
        if (type === "ContentfulReviewBlock") {
            return <ReviewBlock {...block} key={index}/>
        }
        if (type === "ContentfulHomePageShopBlock") {
       return <>
          <ProductBlockWrapper key={index}>
            <HeadlinesWrapper>
              <Headlines>
                Explore The Menu
              </Headlines>
              {/* <Headlines2>
                <ProductsHeadline> {block.shopHeading} </ProductsHeadline>
              </Headlines2> */}
              {/* <ButtonContainer>
              
              </ButtonContainer> */}
              {/* <ProductsSubHeadline> FEATURED PRODUCTS </ProductsSubHeadline> */}
            </HeadlinesWrapper>
            <ProductLandingPageBlock data={block} />
            <div style={{display: "flex", justifyContent: "center"}}>
            <InternalLink to={"/shop"}>{"See The Full Menu"}</InternalLink>
            </div>
          </ProductBlockWrapper>
        </>
      }
        if (type === "ContentfulZipCodeBlock")
          return <><DeliveryZone {...block}></DeliveryZone></>
      })}
    </>
  )
}

export default Blocks
