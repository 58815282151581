import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"
import colors from "src/styles/colors"
import fonts from "src/styles/fonts"
import breakpoints from "src/styles/breakpoints"
import { Col, Row } from "styled-bootstrap-grid"
import CtaButton from "../Core/CtaButton"
import Text from "../Blocks/Text"

const BlockContainer = styled.div`
  background-color: #07408a;
  bottom: 3px;
  position: relative;
  @media (max-width: ${breakpoints.md}) {
    ${'' /* display: none; */}
  }
`

const Image = styled.img`
  width: 80vw;
  max-width: 1600px;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 50px;
  margin-right: 50px;

  @media (max-width: ${breakpoints.md}) {
    width: 800px;
    margin-left: 20px;
    margin-right: 20px;
  
  }
`
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: ${breakpoints.md}) {
   justify-content: left;
    margin-left: auto;
    margin-right: auto;
    overflow: scroll;
  }
`

const MobileVersion = styled.div`
  display: none;
  @media (max-width: ${breakpoints.md}) {
    display: block;
  }
`

const Table = styled.table`
  ${'' /* border-radius: 10px; */}
  margin: 0 auto;
  border-color: white;
  border-width: 2px;
  border-style: solid;
  width: 80%;

  tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
   
  }

  tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
  }
`

const TR = styled.tr``

const TH = styled.th`
  border-color: white;
  border-width: 4px;
  border-style: solid;
  padding: 5px;
`

const TD = styled.th`
  border-color: white;
  border-width:  4px;
  border-style: solid;
  color: white;
  line-height: 125%;
  padding: 15px;
  font-size: calc(.7vw + 0.6em);
`

const Content = styled.div`
  background-color: #07408a;
`
const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
 
  
  color: white;
  text-transform: uppercase;
`
const H3 = styled.h3`
  margin-top: 50px;
`

const H2 = styled.h2`
  margin-top: 50px;
  text-align: center;
  width: 60%;
  @media (max-width: ${breakpoints.md}){
    width: auto;
  }
`

const H4 = styled.h4`
  color: #eb9cc3;
  line-height: 125%;
  font-size: calc(.7vw + 0.6em);
`
const CompetitionBlock = ({ headline, image }) => {
  return (
    <Content>
      <BlockContainer>
      <CenterDiv>
      <H2>WHY CHOOSE <span style={{color: "#EB9CC3", fontStyle: "italic"}}>IN GOOD COMPANY?</span></H2>
      </CenterDiv>
        <Wrapper>
          <Image src={image.file.url} />
        </Wrapper>
      </BlockContainer>
      {/* <MobileVersion>
        <Table>
          <TR>
            <TH>{" "}</TH>
            <TH>
              <H4>MEAL KIT DELIVERY</H4>
            </TH>
            <TH>
              <H4>DELIVERY APP</H4>
            </TH>

            <TH>
              <H4>IN GOOD COMPANY</H4>
            </TH>
            <TH>
              <H4>EATING OUT AT A RESTAURANT</H4>
            </TH>
           
          </TR>
          <TR>
            <TH><H4>PRICE</H4></TH>
            <TD>$$</TD>
            <TD>$$$</TD>
            <TD>
            $$
            </TD>
            <TD>
            $$$$$
            </TD>
          </TR>
          <TR>
         <TH><H4>QUALITY</H4></TH>
         <TD>⭐️</TD>
            <TD>⭐️⭐️</TD>
            <TD>
            ⭐️⭐️⭐️⭐️⭐️
            </TD>
            <TD>
            ⭐️⭐️⭐️⭐️⭐️
            </TD>
          </TR>
          <TR>
          <TH><H4>SUSTAINABILITY</H4></TH>
          <TD>🗑🗑🗑🗑</TD>
            <TD>🗑🗑🗑</TD>
            <TD>
            🌍🌍
            </TD>
            <TD>
            🌍
            </TD>
          </TR>
          <TR>
          <TH><H4>FLEXIBILITY</H4></TH>
          <TD>tough luck if you change your mind!</TD>
            <TD>on demand</TD>
            <TD>
            order whenever you want & keep on hand!
            </TD>
            <TD>
            on demand
            </TD>
          </TR>
          <TR>
          <TH><H4>VARIETY</H4></TH>
          <TD>the same dishes, month after month</TD>
            <TD>same old soggy fries</TD>
            <TD>
            <b>NEW</b> dishes drop every few months
            </TD>
            <TD>
            sometimes a new menu
            </TD>
          </TR>
        </Table>
      </MobileVersion> */}
    </Content>
  )
}

export default CompetitionBlock
